<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <p v-if="loading" class="">Loading additional data...</p>
      <div v-else class="">
        <div v-html="formattedActivityText"></div>
        <div
          v-if="isClaimsFailedActivity"
          v-on:click="failedClaimsDataExpanded = !failedClaimsDataExpanded"
          class="flex items-center gap-x-5  mt-2"
        >
          <span class="font-medium">View Details</span>
          <font-awesome-icon
            :icon="[
              'fal',
              !failedClaimsDataExpanded ? 'chevron-right' : 'chevron-down'
            ]"
            class="text-blue-700 hover:text-blue-400 mr-2"
          />
        </div>
        <section
          v-if="failedClaimsDataExpanded"
          v-html="formatFailedClaimsData()"
        ></section>
        <button
          class="p-0 border-none text-atlas-blue font-medium"
          v-if="actionText && actionName && actionData"
          @click="$emit(actionName, actionData)"
        >
          {{ actionText }}
        </button>
      </div>
    </el-timeline-item>
  </div>
</template>

<script>
import { companiesMapGetters } from "@/store/modules/companies";
import { authMapGetters } from "@/store/modules/auth";
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
import { gbsHelpMapActions } from "@/store/modules/gbshelp";
import { get } from "lodash";
export default {
  data: () => ({
    actionText: "",
    actionName: "",
    actionData: null,
    loading: false,
    isClaimsFailedActivity: false,
    failedClaimsDataExpanded: false
  }),
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  mixins: [ActivitiesTimelineMixin],
  methods: {
    ...gbsHelpMapActions(["getGbsHelps"]),
    getCompany(companyId) {
      const matchedCompany = this.getCompanyById(companyId);
      return matchedCompany ? matchedCompany.companyName : "N/A";
    },
    async generateReadableUpdateAgency() {
      let contentHtml = ``;
      let companiesArray = [];
      for (let update of this.updates) {
        if (update.field.includes("companyIds")) {
          companiesArray.push(this.getCompany(update.update));
        } else if (update.field.includes("underWriterId")) {
          const user = await this.getUser(update.update);
          const href = this.baseLink(update.update);
          contentHtml =
            contentHtml +
            `<p>{userName} updated the agency's underwriter:
            <a style="color: ${this.linkColorDark}" href="${href}"> ${user.fullName}</a></p>`;
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }
      if (companiesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated the agency's companies: ${companiesArray.toString()}</p>`;
      }
      this.setReadableUpdateProps(contentHtml);
    },
    formatFailedClaimsData() {
      let html = "";
      for (const update of this.data.updates) {
        html += `<p>${update.field}: ${update.update}</p>`;
      }
      return html;
    },
    generateReadableUpdateCompany() {
      let contentHtml = ``;
      let linesOfBusinessArray = [];
      let HOFormTypesArray = [];
      let DFFormTypesArray = [];
      let TCFormTypesArray = [];
      let companyPrefixArray = [];

      for (let update of this.updates) {
        if (update.field.includes("companyLineOfBusiness")) {
          linesOfBusinessArray.push(update.update);
        } else if (update.field.includes("formName")) {
          switch (update.field[0]) {
            case "H":
              HOFormTypesArray.push(update.update);
              break;
            case "D":
              DFFormTypesArray.push(update.update);
              break;
            case "T":
              TCFormTypesArray.push(update.update);
              break;
          }
        } else if (update.field.includes(".value")) {
          continue;
        } else if (update.field.includes("companyPrefix")) {
          companyPrefixArray.push(update.update);
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }
      if (linesOfBusinessArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated the companies' lines of business. ${linesOfBusinessArray.toString()}</p>`;
      }
      if (HOFormTypesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated Homeowner form types. ${HOFormTypesArray.toString()}</p>`;
      }
      if (DFFormTypesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated Dwelling fire form types. ${DFFormTypesArray.toString()}</p>`;
      }
      if (TCFormTypesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated Tenant/Condo form types. ${TCFormTypesArray.toString()}</p>`;
      }
      if (companyPrefixArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated the company prefixes. ${companyPrefixArray.toString()}</p>`;
      }
      this.setReadableUpdateProps(contentHtml);
    },
    generateReadableUpdateEndorsement() {
      let contentHtml = ``;
      let companiesArray = [];
      let formTypesArray = [];
      let appliesToArray = [];

      for (let update of this.updates) {
        if (update.field.includes("companies")) {
          const company = this.getCompanyByCompanyNumber(
            parseInt(update.update)
          );
          companiesArray.push(company?.companyName);
        } else if (update.field.includes("formTypes")) {
          formTypesArray.push(update.update);
        } else if (update.field.includes("appliesTo")) {
          appliesToArray.push(update.update);
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }
      if (companiesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated companies: ${companiesArray.toString()}</p>`;
      }
      if (formTypesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated form types: ${formTypesArray.toString()}</p>`;
      }
      if (appliesToArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated lines of business: ${appliesToArray.toString()}</p>`;
      }
      this.setReadableUpdateProps(contentHtml);
    },
    generateReadableUpdateUnderWritingQuestions() {
      let contentHtml = ``;
      let companiesArray = [];
      let policyType = [];
      let policyTypeArray = [];

      const underWritingQuestion = {
        name: "Underwriting Question",
        href: `/admin/underwriting-questions/${this.data.underWritingQuestionId}/edit`
      };

      for (let update of this.updates) {
        if (update.field.includes("companies")) {
          const company = this.getCompanyByCompanyNumber(
            parseInt(update.update)
          );
          companiesArray.push(company.companyName);
        } else if (update.field.includes("policyType")) {
          policyTypeArray.push(update.update);
        } else {
          contentHtml = `<p>{userName} updated "${update.field.replace(
            "data.",
            ""
          )}" to "${
            update.update || typeof update.update === "boolean"
              ? update.update
              : "N/A"
          }."  of {underWritingQuestion}</p>`;
        }
      }
      if (companiesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated companies: ${companiesArray.toString()} of {underWritingQuestion}</p>`;
      }
      if (policyType.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated policy type: ${policyType.toString()} of {underWritingQuestion}</p>`;
      }

      this.link = { ...this.accountTypeFormatted, underWritingQuestion };
      this.content = contentHtml;
      this.icon = "el-icon-edit";
    },
    generateReadableAddGbsHelp({ content, icon, type, link }) {
      this.content = `{userName} ${content} (${this.data.gbsHelpId})`;
      this.icon = icon;
      this.type = type;
      this.link = link ? link : this.accountTypeFormatted;
    },
    async generateReadableUpdatePPC() {
      let contentHtml = ``;
      let territoryUpdatesArray = [];
      let gbsHelp = {};
      //wrapping in try catch because a deleted ppc might throw an error in api call
      try {
        gbsHelp = await this.getGbsHelps({
          query: { _id: [this.data.gbsHelpId] }
        });
        // eslint-disable-next-line no-empty
      } catch (e) {}
      for (let update of this.updates) {
        if (update.field.includes("territoryInfo.companyTerritories")) {
          const stringArray = update.field.split(".");
          const field = stringArray[stringArray.length - 1];
          const index = update.field.indexOf("[") + 1;
          const num = update.field[index];
          territoryUpdatesArray[num] += ` <b>${field}:</b> ${update.update}`;
        } else if (update.field.includes("gbsHelpId")) {
          continue;
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }
      let territoryDeletesArray = [];
      for (let del of this.deletes) {
        if (del.field.includes("_id")) {
          continue;
        } else if (del.field.includes("companyTerritories")) {
          const stringArray = del.field.split(".");
          const field = stringArray[stringArray.length - 1];
          const index = del.field.indexOf("[") + 1;
          const num = del.field[index];
          territoryDeletesArray[num] += ` <b>${field}:</b> ${del.update}`;
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, del);
        }
      }
      if (territoryUpdatesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated the territory info for ppc ${get(
            gbsHelp,
            "0.territoryInfo.ppc",
            this.data.gbsHelpId
          )}: ${territoryUpdatesArray
            .toString()
            .replaceAll("undefined <b>_id", "</p><p>UPDATE <b>_id")
            .replaceAll("undefined", "</p><p>ADDED ")}</p>`;
      }
      if (territoryDeletesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} deleted a territory: ${territoryDeletesArray
            .toString()
            .replaceAll("undefined", "</p><p>")}</p>`;
      }
      this.setReadableUpdateProps(contentHtml);
    },
    restrictedUsersTextFormatted(userIds, isRestricted) {
      if (userIds && userIds.length) {
        const userCount = userIds.length;
        return `restricted ${userCount} user${
          userCount > 1 ? "s" : ""
        } from ${this.formatDate(isRestricted.startDate)} to ${this.formatDate(
          isRestricted.endDate
        )}`;
      }
    },
    pluralizeWord(word, number) {
      const pluralRules = new Intl.PluralRules("en-US");
      const pluralForm = pluralRules.select(number);
      const wordMapper = {
        one: `${number} ${word}`,
        other: `${number} ${word}s`
      };
      return wordMapper[pluralForm];
    }
  },
  computed: {
    ...companiesMapGetters(["getCompanyById"]),
    ...authMapGetters(["getCompanyByCompanyNumber"]),
    userUrl() {
      return `users/${this.data.userId}`;
    },
    getClaimsPolicyNumber() {
      const data = this.data?.updates?.find(claim => {
        return claim.field === "data.policyNumber";
      });
      return data?.update || "N/A";
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "ADD_POLICY_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created a policy change request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_PAY_PLAN_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created a pay plan change request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_CANCELLATION_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created a cancellation change request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_CLAIMS_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created a claims request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_CLAIMS_REQUEST_FAILURE": {
            this.isClaimsFailedActivity = true;
            this.generateReadableFailGeneric({
              content: `failed to file a claim with policyNumber ${this.getClaimsPolicyNumber}`,
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "UPDATE_CLAIMS_REQUEST_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to update a claim.",
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_CLAIMS_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted a claims request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_CLAIMS_REQUEST_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete a claim.",
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_AGENCY_SUCCESS":
            this.generateReadableGeneric({
              content: "created a agency.",
              icon: "el-icon-s-home",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_AGENCY_SUCCESS":
            await this.generateReadableUpdateAgency();
            break;
          case "ADD_COMPANY_SUCCESS":
            this.generateReadableGeneric({
              content: "created a company.",
              icon: "el-icon-office-building",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_ACCREDITED_BUILDER_SUCCESS":
            this.generateReadableGeneric({
              content: "created an accredited builder.",
              icon: "el-icon-office-building",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_COMPANY_SUCCESS":
            this.generateReadableUpdateCompany();
            break;
          case "ADD_MORTGAGE_SUCCESS":
            this.generateReadableGeneric({
              content: "added a mortgage.",
              icon: "el-icon-circle-check",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_ENDORSEMENT_SUCCESS":
            this.generateReadableGeneric({
              content: "added a endorsement.",
              icon: "el-icon-circle-check",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_ENDORSEMENT_SUCCESS":
            this.generateReadableUpdateEndorsement();
            break;
          case "APPLIES_TO_ENDORSEMENT_SUCCESS":
            {
              const companyNumbers = this.activity.data?.companies || [];
              this.generateReadableGeneric({
                content: `applied endorsement ${this.activity.data?.formNumbers.join(
                  ", "
                )} to company ${companyNumbers?.join(",") || ""}.`,
                icon: "el-icon-circle-check",
                type: "success"
              });
            }
            break;
          case "APPLIES_TO_ENDORSEMENT_FAILURE": {
            const companyNumbers = this.activity.data?.companies || [];
            this.generateReadableFailGeneric({
              content: `failed to apply endorsement(s) to company  ${companyNumbers?.join(
                ","
              ) || ""}.`,
              icon: "el-icon-error"
            });
            break;
          }
          case "ADD_UNDERWRITING_QUESTION_SUCCESS": {
            const underWritingQuestion = {
              name: "Underwriting Question",
              href: `/admin/underwriting-questions/${this.data.underWritingQuestionId}/edit`
            };
            this.generateReadableGeneric({
              content: "added a underwriting question {underWritingQuestion}.",
              icon: "el-icon-circle-check",
              type: "success",
              link: { ...this.accountTypeFormatted, underWritingQuestion }
            });
            break;
          }
          case "UPDATE_UNDERWRITING_QUESTION_SUCCESS":
            this.generateReadableUpdateUnderWritingQuestions();
            break;
          case "ADD_COUNTY_SUCCESS":
            this.generateReadableGeneric({
              content: "added a county.",
              icon: "el-icon-circle-check",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_UNDERWRITING_QUESTION_FAILURE": {
            const underWritingQuestion = {
              name: "Underwriting Question",
              href: `/admin/underwriting-questions/${this.data.underWritingQuestionId}/edit`
            };
            this.generateReadableFailGeneric({
              content: `failed to update a underwriting question {underWritingQuestion}.`,
              icon: "el-icon-error",
              link: { ...this.accountTypeFormatted, underWritingQuestion }
            });
            break;
          }
          case "UPDATE_PAY_PLAN_CHANGE_REQUEST_SUCCESS":
          case "UPDATE_POLICY_CHANGE_REQUEST_SUCCESS":
          case "UPDATE_CANCELLATION_CHANGE_REQUEST_SUCCESS":
          case "UPDATE_CLAIMS_REQUEST_SUCCESS":
          case "UPDATE_MORTGAGE_SUCCESS":
          case "UPDATE_COUNTY_SUCCESS":
            this.generateReadableUpdate();
            break;
          case "UPDATE_PAY_PLAN_CHANGE_REQUEST_FAILURE":
          case "UPDATE_POLICY_CHANGE_REQUEST_FAILURE":
          case "UPDATE_CANCELLATION_CHANGE_REQUEST_FAILURE":
          case "UPDATE_MORTGAGE_FAILURE":
          case "UPDATE_ENDORSEMENT_FAILURE":
          case "UPDATE_AGENCY_FAILURE":
          case "UPDATE_COMPANY_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to update.",
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_PAY_PLAN_CHANGE_REQUEST_FAILURE":
          case "DELETE_POLICY_CHANGE_REQUEST_FAILURE":
          case "DELETE_CANCELLATION_CHANGE_REQUEST_FAILURE":
          case "DELETE_MORTGAGE_FAILURE":
          case "DELETE_ENDORSEMENT_FAILURE":
          case "DELETE_AGENCY_FAILURE":
          case "DELETE_COMPANY_FAILURE":
          case "DELETE_ACCREDITED_BUILDER_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete.",
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_UNDERWRITING_QUESTION_FAILURE": {
            const underWritingQuestion = {
              name: "Underwriting Question",
              href: `/admin/underwriting-questions/${this.data.underWritingQuestionId}/edit`
            };
            this.generateReadableFailGeneric({
              content: "failed to delete {underWritingQuestion}",
              icon: "el-icon-error",
              link: { ...this.accountTypeFormatted, underWritingQuestion }
            });
            break;
          }
          case "DELETE_CANCELLATION_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted a cancellation change request.",
              icon: "el-icon-document-delete",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_CANCELLATION_CHANGE_REQUEST_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to create a cancellation change request.",
              icon: "el-icon-document-delete",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_CANCELLATION_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted ${this.data.cancellationChangeRequestIds.length} billing change requests`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_CANCELLATION_CHANGE_REQUEST_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete multiple billing change requests",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_USER_SUCCESS":
            this.generateReadableGeneric({
              content: `added ${this.data.userIds.length} users: `,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_USER_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to add multiple new users`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "BULK_AGENT_BANNING_OR_RESTRICTION":
            this.generateReadableGeneric({
              content: `banned or restricted ${this.data.emails.length} users`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_USER_SUCCESS":
            this.generateReadableGeneric({
              content: "updated an account.",
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_USER_FAILURE":
            this.generateReadableGeneric({
              content: "failed to add a new user.",
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_USER_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted a user.",
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "RESTORE_USER_SUCCESS":
            this.generateReadableGeneric({
              content: `restored a user. | <a href='${this.userUrl}' class='text-atlas-blue font-bold' target='_blank'>View User</a>`,
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "RESTORE_USER_FAILURE":
            this.generateReadableGeneric({
              content: "failed to restore a user.",
              icon: "el-icon-user",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "USER_RESTRICTION_SUCCESS":
            this.generateReadableGeneric({
              content: this.restrictedUsersTextFormatted(
                this.data.userIds,
                this.data.isRestricted
              ),
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "USER_RESTRICTION_FAILURE":
            this.generateReadableGeneric({
              content: "failed to restrict users.",
              icon: "el-icon-user",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "UNBANNED_USER_SUCCESS":
            this.generateReadableGeneric({
              content: `unbanned ${this.pluralizeWord(
                "user",
                this.data.userIds.length
              )}.`,
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UNBANNED_USER_FAILURE":
            this.generateReadableGeneric({
              content: `failed to unban ${this.pluralizeWord(
                "user",
                this.data.userIds.length
              )}.`,
              icon: "el-icon-user",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_USER_FAILURE":
            this.generateReadableGeneric({
              content: "failed to delete a user.",
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "BAN_USER_SUCCESS":
            this.generateReadableGeneric({
              content: `banned ${this.pluralizeWord(
                "user",
                this.data.userIds.length
              )}.`,
              icon: "el-icon-user",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "BAN_USER_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to ban ${this.pluralizeWord(
                "user",
                this.data.userIds.length
              )}.`,
              icon: "el-icon-user",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "DERESTRICT_USER_SUCCESS":
            this.generateReadableGeneric({
              content: "derestricted a user.",
              icon: "el-icon-user",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;

          case "DERESTRICT_USER_FAILURE":
            this.generateReadableGeneric({
              content: "failed to derestrict a user.",
              icon: "el-icon-user",
              type: "danger",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_POLICY_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: `added ${this.data.policyChangeRequestIds.length} policies`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_POLICY_CHANGE_REQUEST_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to add multiple policies",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_POLICY_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted ${this.data.policyChangeRequestIds.length} policies`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_POLICY_CHANGE_REQUEST_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete multiple policies",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_UNDERWRITING_QUESTION_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted ${this.data.underWritingQuestionIds.length} underwriting questions`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_UNDERWRITING_QUESTION_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to delete ${this.data.underWritingQuestionIds.length} underwriting questions.`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_PAY_PLAN_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted ${this.data.payPlanChangeRequestIds.length} pay plans`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_PAY_PLAN_CHANGE_REQUEST_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete multiple pay plans",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_COUNTY_SUCCESS":
            this.generateReadableGeneric({
              content: `added ${this.data.countyIds.length} counties`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_COUNTY_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to add multiple counties",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_COUNTY_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted ${this.data?.countyIds?.length} counties`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "PROHIBIT_BINDING_COUNTY_SUCCESS":
            this.generateReadableGeneric({
              content: `${
                this.data.prohibitBinding ? "prohibited" : "allowed"
              } binding on ${
                this.data.countyCodes ? this.data.countyCodes.join(",") : ""
              } counties`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "PROHIBIT_BINDING_COUNTY_FAILURE":
            this.generateReadableGeneric({
              content: `failed to ${
                this.data.prohibitBinding ? "prohibit" : "allow"
              } binding on ${
                this.data.countyCodes ? this.data.countyCodes.join(",") : ""
              } counties`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_COUNTY_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete multiple counties",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_ENDORSEMENT_SUCCESS":
            this.generateReadableGeneric({
              content: `added ${this.data.endorsementIds.length} endorsements`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_ENDORSEMENT_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to add multiple endorsements`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_ENDORSEMENT_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted ${this.data.endorsementIds.length} endorsements`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_BULK_ENDORSEMENT_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to delete ${this.data.endorsementIds.length} endorsements`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_GBS_HELP_SUCCESS":
            this.generateReadableAddGbsHelp({
              content: "added a ppc.",
              icon: "el-icon-circle-check",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_GBS_HELP_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to add a ppc.",
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_GBS_HELP_SUCCESS":
            this.generateReadableUpdatePPC();
            break;
          case "UPDATE_GBS_HELP_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to update a ppc.`,
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_BULK_GBS_HELP_SUCCESS": {
            const contentExtension = this.activity?.data?.fileName
              ? ` called ${this.activity?.data?.fileName}`
              : "";
            this.generateReadableGeneric({
              content: `uploaded a PPC document${contentExtension}.`,
              icon: "el-icon-circle-check",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          }
          case "ADD_BULK_GBS_HELP_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to add bulk ppcs.",
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>

<style></style>
